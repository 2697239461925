export const apiAuthorizeUrl = 'KEYCLOAK_URL_ENV'
export const apiUrl = 'REST_API_URL_ENV'

//export const apiAuthorizeUrl = 'http://localhost:8080'
//export const apiUrl = 'http://localhost:9002'

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'http://localhost:9002'

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'https://erasmus.dev.gc.axesor.com/'

// export const apiAuthorizeUrl = 'https://nep.pre.gc.axesor.com/auth/'
// export const apiUrl = 'wss://erasmus.pre.gc.axesor.com/'
//
// export const apiAuthorizeUrl = 'https://nep.pro.gc.axesor.com/auth/'
// export const apiUrl = 'wss://erasmus.pro.gc.axesor.com/'