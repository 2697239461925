import { createApp } from 'vue'
import App from './App.vue'
import {apiAuthorizeUrl} from "@/config/api";
import Keycloak from 'keycloak-js';

let initOptions = {
  url: apiAuthorizeUrl, realm: 'axesor', clientId: 'erasmus-front', onLoad: 'login-required'
}

const keycloak = new Keycloak(initOptions)

keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      if (!auth) {
        window.location.reload()
      } else
        createApp(App,{keycloak: keycloak})
            .mount('#app')

      setInterval(() => {
        keycloak
            .updateToken(70)
            .catch(() => {
              console.log('Failed to refresh token')
            })
      }, 6000)

    }).catch((error) => {
  console.log(error)
  console.log("Authenticated Failed")
})


